import { Save } from 'lucide-react';
import { useState } from 'react';
import toast from 'react-hot-toast';

interface SettingsState {
  siteName: string;
  siteDescription: string;
  enableComments: boolean;
  moderationRequired: boolean;
}

export default function Settings() {
  const [settings, setSettings] = useState<SettingsState>({
    siteName: 'My Website',
    siteDescription: 'A modern website built with React',
    enableComments: true,
    moderationRequired: true,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      toast.success('Settings saved successfully');
    } catch (error) {
      toast.error('Failed to save settings');
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Settings</h1>

      <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="siteName" className="block text-sm font-medium text-gray-700">
              Site Name
            </label>
            <input
              id="siteName"
              type="text"
              value={settings.siteName}
              onChange={(e) =>
                setSettings({ ...settings, siteName: e.target.value })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="siteDescription" className="block text-sm font-medium text-gray-700">
              Site Description
            </label>
            <textarea
              id="siteDescription"
              value={settings.siteDescription}
              onChange={(e) =>
                setSettings({ ...settings, siteDescription: e.target.value })
              }
              rows={3}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center">
              <input
                id="enableComments"
                type="checkbox"
                checked={settings.enableComments}
                onChange={(e) =>
                  setSettings({ ...settings, enableComments: e.target.checked })
                }
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="enableComments" className="ml-2 block text-sm text-gray-900">
                Enable Comments
              </label>
            </div>

            <div className="flex items-center">
              <input
                id="moderationRequired"
                type="checkbox"
                checked={settings.moderationRequired}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    moderationRequired: e.target.checked,
                  })
                }
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="moderationRequired" className="ml-2 block text-sm text-gray-900">
                Require Moderation
              </label>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}