import React, { useState, useEffect } from 'react';
import { UserButton } from '@clerk/clerk-react';
import { ChevronLeft, ChevronRight, Menu, X, LucideIcon, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

interface NavigationItem {
  name: string;
  href?: string;
  icon: LucideIcon;
  children?: {
    name: string;
    href: string;
    icon: LucideIcon;
  }[];
}

interface SidebarProps {
  navigation: NavigationItem[];
  currentPath: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: any; // Using any for now since we removed UserResource type
}

export default function Sidebar({ navigation, currentPath, isOpen, setIsOpen, user }: SidebarProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsOpen]);

  const toggleGroup = (groupName: string) => {
    setExpandedGroups(prev => 
      prev.includes(groupName)
        ? prev.filter(name => name !== groupName)
        : [...prev, groupName]
    );
  };

  return (
    <>
      {/* Mobile Menu Button */}
      {isMobile && !isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="fixed top-4 left-4 z-40 p-2 rounded-lg bg-white shadow-md"
        >
          <Menu size={24} />
        </button>
      )}

      {/* Overlay for mobile */}
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`
          fixed inset-y-0 left-0 bg-white border-r border-gray-200 z-50
          transition-transform duration-300 ease-in-out
          ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
          ${isOpen ? 'w-64' : 'w-20'}
          flex flex-col
        `}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          {isOpen && (
            <h1 className="text-xl font-bold text-gray-800">Admin CMS</h1>
          )}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            {isMobile ? (
              <X size={20} />
            ) : isOpen ? (
              <ChevronLeft size={20} />
            ) : (
              <ChevronRight size={20} />
            )}
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto px-2 py-4">
          {navigation.map((item) => (
            <div key={item.name} className="mb-2">
              {item.children ? (
                <div>
                  <button
                    onClick={() => toggleGroup(item.name)}
                    className={`w-full flex items-center justify-between px-3 py-2 rounded-lg transition-colors duration-200
                      ${expandedGroups.includes(item.name) ? 'bg-gray-100' : ''}
                      ${isOpen ? 'text-left' : 'justify-center'}
                    `}
                  >
                    <div className="flex items-center">
                      <item.icon size={20} />
                      {isOpen && <span className="ml-3">{item.name}</span>}
                    </div>
                    {isOpen && <ChevronDown size={16} className={`transform transition-transform duration-200 ${expandedGroups.includes(item.name) ? 'rotate-180' : ''}`} />}
                  </button>
                  {isOpen && expandedGroups.includes(item.name) && (
                    <div className="ml-6 mt-2 space-y-1">
                      {item.children.map((child) => (
                        <Link
                          key={child.href}
                          to={child.href}
                          className={`flex items-center px-3 py-2 rounded-lg transition-colors duration-200
                            ${currentPath === child.href ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}
                          `}
                        >
                          <child.icon size={18} />
                          <span className="ml-3">{child.name}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to={item.href!}
                  className={`flex items-center px-3 py-2 rounded-lg transition-colors duration-200
                    ${currentPath === item.href ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}
                    ${!isOpen && 'justify-center'}
                  `}
                >
                  <item.icon size={20} />
                  {isOpen && <span className="ml-3">{item.name}</span>}
                </Link>
              )}
            </div>
          ))}
        </nav>

        {/* User Profile */}
        <div className="p-4 border-t border-gray-200">
          <div className="flex items-center gap-3">
            <UserButton afterSignOutUrl="/sign-in" />
            {isOpen && user && (
              <div className="flex flex-col overflow-hidden">
                <span className="text-sm font-medium text-gray-900 truncate">
                  {user.fullName}
                </span>
                <span className="text-xs text-gray-500 truncate">
                  {user.primaryEmailAddress?.emailAddress}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}