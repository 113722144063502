// src/lib/api.ts
import { useAuth } from '@clerk/clerk-react';

// Types
interface BaseEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface Donation extends BaseEntity {
  title: string;
  date: string;
  description: string;
  location: string;
  imageUrl?: string;
}

interface Event extends BaseEntity {
  title: string;
  date: string;
  location: string;
  seatsAvailable: number;
  fee: number;
  googleMapsLink: string;
  imageUrl?: string;
}

interface Activity extends BaseEntity {
  title: string;
  date: string;
  description: string;
}

interface Partner extends BaseEntity {
  name: string;
  logoUrl: string;
}

interface Contact {
  id: string;
  fullName: string;
  email: string;
  message: string;
  createdAt: string;
}

interface Poster extends BaseEntity {
  title: string;
  startDate: string;
  endDate: string;
  imageUrl: string;
  active: boolean;
}

// API Error Type
interface ApiError {
  error: string;
  cause?: unknown;
}

// API Base URL
const API_URL = process.env.VITE_API_URL || 'http://localhost:8788';

// API Client Hook
export const useApi = () => {
  const { getToken } = useAuth();

  const fetchWithAuth = async <T>(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<T> => {
    try {
      const token = await getToken();
      const headers = {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...options.headers,
      };

      const response = await fetch(`${API_URL}${endpoint}`, {
        ...options,
        headers,
      });

      if (!response.ok) {
        const errorData: ApiError = await response.json();
        throw new Error(errorData.error || 'API request failed');
      }

      return response.json();
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

  return {
    // Donations
    getDonations: () => 
      fetchWithAuth<Donation[]>('/donations'),

    createDonation: (data: FormData) => 
      fetchWithAuth<Donation>('/donations', {
        method: 'POST',
        body: data,
      }),

    updateDonation: (id: string, data: FormData) => 
      fetchWithAuth<Donation>(`/donations/${id}`, {
        method: 'PUT',
        body: data,
      }),

    deleteDonation: (id: string) => 
      fetchWithAuth<{ message: string }>(`/donations/${id}`, {
        method: 'DELETE',
      }),

    // Events
    getEvents: () => 
      fetchWithAuth<Event[]>('/events'),

    createEvent: (data: FormData) => 
      fetchWithAuth<Event>('/events', {
        method: 'POST',
        body: data,
      }),

    updateEvent: (id: string, data: FormData) => 
      fetchWithAuth<Event>(`/events/${id}`, {
        method: 'PUT',
        body: data,
      }),

    deleteEvent: (id: string) => 
      fetchWithAuth<{ message: string }>(`/events/${id}`, {
        method: 'DELETE',
      }),

    // Activities
    getActivities: () => 
      fetchWithAuth<Activity[]>('/activities'),

    createActivity: (data: Omit<Activity, 'id' | 'createdAt' | 'updatedAt'>) => 
      fetchWithAuth<Activity>('/activities', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }),

    updateActivity: (id: string, data: Partial<Omit<Activity, 'id' | 'createdAt' | 'updatedAt'>>) => 
      fetchWithAuth<Activity>(`/activities/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }),

    deleteActivity: (id: string) => 
      fetchWithAuth<{ message: string }>(`/activities/${id}`, {
        method: 'DELETE',
      }),

    // Partners
    getPartners: () => 
      fetchWithAuth<Partner[]>('/partners'),

    createPartner: (data: FormData) => 
      fetchWithAuth<Partner>('/partners', {
        method: 'POST',
        body: data,
      }),

    deletePartner: (id: string) => 
      fetchWithAuth<{ message: string }>(`/partners/${id}`, {
        method: 'DELETE',
      }),

    // Contacts (Public submission)
    submitContact: (data: Omit<Contact, 'id' | 'createdAt'>) => 
      fetchWithAuth<Contact>('/contacts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }),

    // Contacts (Protected admin routes)
    getContacts: () => 
      fetchWithAuth<Contact[]>('/contacts'),

    deleteContact: (id: string) => 
      fetchWithAuth<{ message: string }>(`/contacts/${id}`, {
        method: 'DELETE',
      }),

    // Posters
    getPosters: () => 
      fetchWithAuth<Poster[]>('/posters'),

    getAllPosters: () => 
      fetchWithAuth<Poster[]>('/admin/posters'),

    createPoster: (data: FormData) => 
      fetchWithAuth<Poster>('/posters', {
        method: 'POST',
        body: data,
      }),

    updatePoster: (id: string, data: FormData) => 
      fetchWithAuth<Poster>(`/posters/${id}`, {
        method: 'PUT',
        body: data,
      }),

    togglePosterStatus: (id: string) => 
      fetchWithAuth<Poster>(`/posters/${id}/toggle`, {
        method: 'PATCH',
      }),

    deletePoster: (id: string) => 
      fetchWithAuth<{ message: string }>(`/posters/${id}`, {
        method: 'DELETE',
      }),
  };
};

export const useActivities = () => {
  const api = useApi();

  const createActivity = async (data: {
    title: string;
    date: string;
    description: string;
  }) => {
    return api.createActivity(data);
  };

  return {
    list: api.getActivities,
    create: createActivity,
    update: api.updateActivity,
    delete: api.deleteActivity,
  };
}

export const useDonations = () => {
  const api = useApi();

  const createDonation = async (data: {
    title: string;
    date: string;
    description: string;
    location: string;
    image?: File;
  }) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('date', data.date);
    formData.append('description', data.description);
    formData.append('location', data.location);
    if (data.image) {
      formData.append('image', data.image);
    }

    return api.createDonation(formData);
  };

  const updateDonation = async (
    id: string,
    data: {
      title: string;
      date: string;
      description: string;
      location: string;
      image?: File;
    }
  ) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('date', data.date);
    formData.append('description', data.description);
    formData.append('location', data.location);
    if (data.image) {
      formData.append('image', data.image);
    }

    return api.updateDonation(id, formData);
  };

  return {
    list: api.getDonations,
    create: createDonation,
    update: updateDonation,
    delete: api.deleteDonation,
  };
};

export const useEvents = () => {
  const api = useApi();

  const createEvent = async (data: {
    title: string;
    date: string;
    location: string;
    seatsAvailable: number;
    fee: number;
    googleMapsLink: string;
    image?: File;
  }) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('date', data.date);
    formData.append('location', data.location);
    formData.append('seatsAvailable', data.seatsAvailable.toString());
    formData.append('fee', data.fee.toString());
    formData.append('googleMapsLink', data.googleMapsLink);
    if (data.image) {
      formData.append('image', data.image);
    }

    return api.createEvent(formData);
  };

  return {
    list: api.getEvents,
    create: createEvent,
    update: api.updateEvent,
    delete: api.deleteEvent,
  };
};

export const usePosters = () => {
  const api = useApi();

  const createPoster = async (data: {
    title: string;
    startDate: string;
    endDate: string;
    active: boolean;
    image: File;
  }) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('startDate', data.startDate);
    formData.append('endDate', data.endDate);
    formData.append('active', data.active.toString());
    formData.append('image', data.image);

    return api.createPoster(formData);
  };

  return {
    list: api.getPosters,
    listAll: api.getAllPosters,
    create: createPoster,
    update: api.updatePoster,
    toggle: api.togglePosterStatus,
    delete: api.deletePoster,
  };
};