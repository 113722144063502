import { ClerkProvider, SignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import AdminDashboard from './components/AdminDashboard';

if (!import.meta.env.VITE_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Clerk Publishable Key');
}

const queryClient = new QueryClient();

function App() {
  return (
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SignedOut>
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
              <SignIn appearance={{
                elements: {
                  card: 'bg-white shadow-xl border-0',
                  headerTitle: 'text-2xl font-bold text-gray-800',
                }
              }} />
            </div>
          </SignedOut>
          <SignedIn>
            <AdminDashboard />
          </SignedIn>
          <Toaster position="top-center" />
        </BrowserRouter>
      </QueryClientProvider>
    </ClerkProvider>
  );
}

export default App;