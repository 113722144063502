import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, X, Upload, Loader2, Eye, EyeOff } from 'lucide-react';
import { format } from 'date-fns';
import { usePosters } from '../lib/api';
import { toast } from 'react-hot-toast';

interface Poster {
  id: string;
  title: string;
  imageUrl: string;
  active: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}

interface PosterFormData {
  title: string;
  startDate: string;
  endDate: string;
  image: File | null;
  active: boolean;
}

export default function PosterManagement() {
  const postersApi = usePosters();
  
  const [posters, setPosters] = useState<Poster[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [previewPoster, setPreviewPoster] = useState<Poster | null>(null);
  
  const [formData, setFormData] = useState<PosterFormData>({
    title: '',
    startDate: '',
    endDate: '',
    image: null,
    active: true,
  });


  useEffect(() => {
    loadPosters();
  }, []);

  const loadPosters = async () => {
    try {
      const data = await postersApi.listAll();
      setPosters(data);
    } catch (error) {
      toast.error('Failed to load posters');
      console.error('Error loading posters:', error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      startDate: '',
      endDate: '',
      image: null,
      active: true,
    });
    setImagePreview('');
    setEditingId(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData(prev => ({ ...prev, image: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('startDate', formData.startDate);
      formDataToSend.append('endDate', formData.endDate);
      formDataToSend.append('active', formData.active.toString());
      if (formData.image) {
        formDataToSend.append('image', formData.image);
      }

      if (editingId) {
        await postersApi.update(editingId, formDataToSend);
        toast.success('Poster updated successfully');
      } else {
        await postersApi.create({
          title: formData.title,
          startDate: formData.startDate,
          endDate: formData.endDate,
          active: formData.active,
          image: formData.image as File,
        });
        toast.success('Poster created successfully');
      }

      await loadPosters();
      setIsModalOpen(false);
      resetForm();
    } catch (error) {
      toast.error(editingId ? 'Failed to update poster' : 'Failed to create poster');
      console.error('Error saving poster:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePosterStatus = async (id: string) => {
    try {
      await postersApi.toggle(id);
      await loadPosters();
      toast.success('Poster status updated');
    } catch (error) {
      toast.error('Failed to update poster status');
      console.error('Error toggling poster status:', error);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this poster?')) return;

    try {
      await postersApi.delete(id);
      await loadPosters();
      toast.success('Poster deleted successfully');
    } catch (error) {
      toast.error('Failed to delete poster');
      console.error('Error deleting poster:', error);
    }
  };

  if (isInitialLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="p-4 lg:p-8">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <h1 className="text-2xl font-bold text-gray-900">Overlay Posters</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Poster
        </button>
      </div>

      {/* Posters Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {posters.map((poster) => (
          <div
            key={poster.id}
            className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
          >
            <div className="aspect-video relative">
              <img
                src={poster.imageUrl}
                alt={poster.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-10 transition-opacity flex items-center justify-center opacity-0 hover:opacity-100">
                <button
                  onClick={() => setPreviewPoster(poster)}
                  className="p-2 bg-white rounded-full text-blue-600 hover:text-blue-900 mx-1"
                >
                  <Eye className="w-4 h-4" />
                </button>
                <button
                  onClick={() => {
                    setEditingId(poster.id);
                    setFormData({
                      title: poster.title,
                      startDate: format(new Date(poster.startDate), 'yyyy-MM-dd'),
                      endDate: format(new Date(poster.endDate), 'yyyy-MM-dd'),
                      image: null,
                      active: poster.active,
                    });
                    setImagePreview(poster.imageUrl);
                    setIsModalOpen(true);
                  }}
                  className="p-2 bg-white rounded-full text-blue-600 hover:text-blue-900 mx-1"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDelete(poster.id)}
                  className="p-2 bg-white rounded-full text-red-600 hover:text-red-900 mx-1"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="p-4">
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-medium text-gray-900">{poster.title}</h3>
                <button
                  onClick={() => togglePosterStatus(poster.id)}
                  className={`p-1 rounded-full ${
                    poster.active ? 'text-green-600' : 'text-gray-400'
                  }`}
                >
                  {poster.active ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                </button>
              </div>
              <div className="text-sm text-gray-500">
                <p>Start: {format(new Date(poster.startDate), 'MMM d, yyyy')}</p>
                <p>End: {format(new Date(poster.endDate), 'MMM d, yyyy')}</p>
              </div>
            </div>
          </div>
        ))}
        {posters.length === 0 && (
          <div className="col-span-full text-center py-12 bg-white rounded-xl border border-gray-200">
            <p className="text-gray-500">No posters found. Click "Add Poster" to create one.</p>
          </div>
        )}
      </div>

      {/* Poster Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl max-w-lg w-full">
            <div className="flex justify-between items-center p-6 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                {editingId ? 'Edit Poster' : 'Add New Poster'}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  resetForm();
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Title
                </label>
                <input
                  type="text"
                  required
                  value={formData.title}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, title: e.target.value }))
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    required
                    value={formData.startDate}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, startDate: e.target.value }))
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    End Date
                  </label>
                  <input
                    type="date"
                    required
                    value={formData.endDate}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, endDate: e.target.value }))
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Poster Image
                </label>
                <div className="flex items-center space-x-4">
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="h-20 w-20 rounded-lg object-cover"
                    />
                  )}
                  <label className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
                    <Upload className="w-4 h-4 mr-2" />
                    <span className="text-sm">Choose Image</span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </label>
                </div>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="active"
                  checked={formData.active}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, active: e.target.checked }))
                  }
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="active" className="ml-2 text-sm text-gray-700">
                  Make poster active
                </label>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    resetForm();
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg flex items-center disabled:opacity-50"
                >
                  {isLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                  {editingId ? 'Update' : 'Create'} Poster
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Poster Preview Modal */}
      {previewPoster && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="max-w-4xl w-full">
            <div className="relative">
              <img
                src={previewPoster.imageUrl}
                alt={previewPoster.title}
                className="w-full rounded-lg"
              />
              <button
                onClick={() => setPreviewPoster(null)}
                className="absolute top-4 right-4 p-2 bg-white rounded-full text-gray-600 hover:text-gray-900"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}