import React from 'react';
import { 
  BarChart, Users, FileText, ArrowUp, Calendar, 
  Heart, Trees, Users2, Building2, Phone, MapPin, 
  DollarSign, Image as ImageIcon
} from 'lucide-react';

// Types
interface Donation {
  id: string;
  title: string;
  date: string;
  description: string;
  location: string;
  image: string;
}

interface Event {
  id: string;
  title: string;
  date: string;
  location: string;
  seatsAvailable: number;
  fee: number;
  googleMapsLink: string;
  image: string;
  rsvpList: RSVPEntry[];
}

interface RSVPEntry {
  fullName: string;
  email: string;
  mpesaPhone: string;
  whatsappPhone: string;
}

interface Activity {
  id: string;
  title: string;
  date: string;
  description: string;
}

interface Partner {
  id: string;
  name: string;
  logo: string;
}

interface Contact {
  id: string;
  fullName: string;
  email: string;
  message: string;
}

interface Statistics {
  booksdonated: number;
  treesPlanted: number;
  beneficiaryCommunities: number;
}

export default function Dashboard() {
  const stats = [
    { name: 'Total Posts', value: '128', icon: FileText, change: '+12.3%' },
    { name: 'Total Users', value: '12.4k', icon: Users, change: '+8.2%' },
    { name: 'Engagement Rate', value: '24.57%', icon: BarChart, change: '+4.1%' },
  ];

  const statistics: Statistics = {
    booksdonated: 1500,
    treesPlanted: 750,
    beneficiaryCommunities: 25
  };

  return (
    <div className="p-4 lg:p-8 min-h-screen bg-gray-50">
      <h1 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-8">Dashboard Overview</h1>
      
      {/* Main Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="bg-white p-4 lg:p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">{stat.name}</p>
                <p className="text-xl lg:text-2xl font-semibold text-gray-900 mt-1">
                  {stat.value}
                </p>
              </div>
              <div className="p-3 bg-blue-50 rounded-lg">
                <stat.icon className="w-5 h-5 lg:w-6 lg:h-6 text-blue-600" />
              </div>
            </div>
            <div className="flex items-center mt-4 text-sm">
              <ArrowUp className="w-4 h-4 text-green-500" />
              <span className="text-green-500 ml-1">{stat.change}</span>
              <span className="text-gray-500 ml-2">vs last month</span>
            </div>
          </div>
        ))}
      </div>

      {/* Impact Statistics */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-6">Impact Statistics</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <StatCard
            icon={Heart}
            value={statistics.booksdonated}
            label="Books Donated"
          />
          <StatCard
            icon={Trees}
            value={statistics.treesPlanted}
            label="Trees Planted"
          />
          <StatCard
            icon={Users2}
            value={statistics.beneficiaryCommunities}
            label="Beneficiary Communities"
          />
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <QuickAdd title="Add Donation" icon={DollarSign} onClick={() => {}} />
        <QuickAdd title="Add Event" icon={Calendar} onClick={() => {}} />
      </div>

      {/* Recent Activity */}
      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100 mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
        <div className="space-y-4">
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="flex items-center justify-between py-3 border-b border-gray-100 last:border-0"
            >
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                  <FileText className="w-5 h-5 text-blue-600" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">New activity added</p>
                  <p className="text-sm text-gray-500">By John Doe • {i} hour ago</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Helper Components
const StatCard = ({ icon: Icon, value, label }: { 
  icon: typeof Heart; 
  value: number; 
  label: string 
}) => (
  <div className="flex items-center space-x-4">
    <div className="p-3 bg-blue-50 rounded-lg">
      <Icon className="w-6 h-6 text-blue-600" />
    </div>
    <div>
      <p className="text-2xl font-semibold text-gray-900">{value.toLocaleString()}</p>
      <p className="text-sm text-gray-500">{label}</p>
    </div>
  </div>
);

const QuickAdd = ({ title, icon: Icon, onClick }: {
  title: string;
  icon: typeof DollarSign;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className="flex items-center justify-between p-6 bg-white rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
  >
    <div className="flex items-center space-x-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <span className="text-lg font-medium text-gray-900">{title}</span>
    </div>
    <ArrowUp className="w-5 h-5 text-gray-400 transform rotate-45" />
  </button>
);