import { useUser } from '@clerk/clerk-react';
import { 
  LayoutDashboard, 
  FileText, 
  Settings as SettingsIcon, 
  Users as UsersIcon,
  Heart,
  Calendar,
  Activity,
  Building2,
  MessageSquare,
  Image as ImageIcon
} from 'lucide-react';
import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashboard from '../pages/Dashboard';
import Posts from '../pages/Posts';
import Settings from '../pages/Settings';
import UsersPage from '../pages/Users';
import DonationsManagement from '../pages/DonationsManagement';
import EventsManagement from '../pages/EventsManagement';
import ActivitiesManagement from '../pages/ActivitiesManagement';
import PartnersManagement from '../pages/PartnersManagement';
import ContactsManagement from '../pages/ContactsManagement';
import PosterManagement from '../pages/PosterManagement';

export default function AdminDashboard() {
  const { user } = useUser();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: LayoutDashboard },
    { name: 'Posts', href: '/posts', icon: FileText },
    { name: 'Users', href: '/users', icon: UsersIcon },
    { 
      name: 'Community',
      icon: Heart,
      children: [
        { name: 'Donations', href: '/donations', icon: Heart },
        { name: 'Events', href: '/events', icon: Calendar },
        { name: 'Activities', href: '/activities', icon: Activity },
      ]
    },
    { 
      name: 'Content',
      icon: FileText,
      children: [
        { name: 'Partners', href: '/partners', icon: Building2 },
        { name: 'Contacts', href: '/contacts', icon: MessageSquare },
        { name: 'Posters', href: '/posters', icon: ImageIcon },
      ]
    },
    { name: 'Settings', href: '/settings', icon: SettingsIcon },
  ];

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar */}
      <div className={`fixed inset-y-0 z-50 ${sidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300 ease-in-out`}>
        <Sidebar
          navigation={navigation}
          currentPath={location.pathname}
          isOpen={sidebarOpen}
          setIsOpen={setSidebarOpen}
          user={user}
        />
      </div>

      {/* Main Content */}
      <div className={`flex-1 ${sidebarOpen ? 'md:pl-64' : 'md:pl-20'} w-full transition-all duration-300 ease-in-out`}>
        <main className="p-4 md:p-8 min-h-screen">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/settings" element={<Settings />} />
            
            {/* Community Routes */}
            <Route path="/donations" element={<DonationsManagement />} />
            <Route path="/events" element={<EventsManagement />} />
            <Route path="/activities" element={<ActivitiesManagement />} />
            
            {/* Content Routes */}
            <Route path="/partners" element={<PartnersManagement />} />
            <Route path="/contacts" element={<ContactsManagement />} />
            <Route path="/posters" element={<PosterManagement />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}